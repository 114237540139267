import React from 'react';
import PopupConsult from '../../../assets/popup/PopupConsult';
import Popup220318 from '../../../assets/popup/Popup220318';

function PopupContent({...props}) {
  const elem = props.contentComponent;
  // const ContentComponent = elem.type ? React.createElement(elem.type, elem.props, elem.props.children) : elem;

  function getTemplate() {
    return {
      'PopupConsult': <PopupConsult />,
      'Popup220318': <Popup220318 />
    }[props.popupConstructor];
  }

  return (
    <div>
      {
        getTemplate()
      }
    </div>
  );
}

export default PopupContent;