import React from "react";

import styles from '../styles/pages/HsCompany.module.scss';
import HistoryItems from "../assets/data/HsHistoryItems.js";

function HsCompany() {
    return (
        <>
        <div className={styles['hs-visual-section']}>
            <strong>Big Data Marketing Platform</strong>
        </div>
        <section className="hs-section">
                <h2 className="blind">휴머스온 소개</h2>
            <div className="hs-section-inner">
                <p className={styles['hs-intro-text']}>
                    <strong>휴머스온은 1998년 설립 이래,</strong> 23년간 꾸준히 고객지향의 디지털 마케팅 컴퍼니로 성장해 왔습니다.
                    급변하는 IT 환경과 복잡한 채널 속에서 소비자 행동에 대한 깊은 통찰을 위해 고객의 경쟁력을 높이는데 최선을 다하고 있습니다.
                </p>
                <p className={styles['hs-intro-text']}>
                    분야별 최고 전문가들의 끊임없는 연구와 최상의 고객 서비스를 통해 언제나 고객과 함께하겠습니다.
                </p>
                <h3 className="hs-section-title">Digital Collaboration</h3>
                <dl className={styles['hs-intro-contents-list']}>
                    <div className={`${styles['hs-intro-contents-item']} ${styles['innovation']}`}>
                        <dt className={styles['hs-intro-contents-title']}>
                            <strong className={styles['hs-intro-contents-title-main']}>혁신</strong>
                            <span className={styles['hs-intro-contents-title-point']}>INNOVATION</span>
                        </dt>
                        <dd className={styles['hs-intro-contents-text']}>빅데이터, AI 기술 혁신을 리드하는 성실한 <strong>Digital Innovator</strong></dd>
                    </div>
                    <div className={`${styles['hs-intro-contents-item']} ${styles['growth']}`}>
                        <dt className={styles['hs-intro-contents-title']}>
                            <strong className={styles['hs-intro-contents-title-main']}>성장</strong>
                            <span className={styles['hs-intro-contents-title-point']}>GROWTH</span>
                        </dt>
                        <dd className={styles['hs-intro-contents-text']}>고객을 위해 최선을 다하고 함께 동반 성장하는 <strong>Digital Partner</strong></dd>
                    </div>
                    <div className={`${styles['hs-intro-contents-item']} ${styles['collaboration']}`}>
                        <dt className={styles['hs-intro-contents-title']}>
                            <strong className={styles['hs-intro-contents-title-main']}>협업</strong>
                            <span className={styles['hs-intro-contents-title-point']}>COLLABORATION</span>
                        </dt>
                        <dd className={styles['hs-intro-contents-text']}>IT 인프라 부족으로 고민하는 고객을 위한 <strong>Digital Family</strong></dd>
                    </div>
                </dl>
            </div>
        </section>
        <section className="hs-section">
            <div className="hs-section-inner">
                <div className="hs-section-title">
                    <h2 className="hs-title-text">humusOn history</h2>
                    <strong className="hs-sub-title-text">기업연혁</strong>
                </div>
                <dl className={styles['hs-history-list']}>
                    {HistoryItems.map((item, index) => (
                        <div className={styles['hs-history-item']} key={index}>
                            <dt className={styles['hs-history-title']}>{item.year}</dt>
                            <dd className={styles['hs-history-contents']}>
                                <ol className={styles['hs-history-contents-list']}>
                                    {item.item.map((innerItem, innerIndex) => (
                                    <li className={styles['hs-history-contents-item']} key={innerIndex}>
                                        <strong className={styles['hs-history-contents-title']}>{innerItem.month}월</strong>
                                        <p className={styles['hs-history-contents-text']}>{innerItem.contents}</p>
                                    </li>
                                    ))}
                                </ol>
                            </dd>
                        </div>
                        ))
                    }
                </dl>
            </div>
        </section>
        </>
    );
}

export default HsCompany;