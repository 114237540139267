import React from "react";
import styles from '../../styles/components/layout/HsTermsHeader.module.scss'

function HsHeader({title}) {
    return (
        <header className={styles['hs-term-header']}>
            <h2 className={styles['hs-term-header-title']}>{title}</h2>
        </header>
    );
}

export default HsHeader;