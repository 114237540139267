import React from 'react';
import styles from '../../styles/components/common/Input.module.scss';


function Input({ inputLabel, inputId, inputType, ...props }) {
  function handleChange(e) {
    const { value } = e.target;
    props.setValue && props.setValue(value);
  }
  return (
    <>
        <div className={styles['hs-input-container']} style={props.style}>
            { inputLabel && <label className={styles['hs-input-label']} htmlFor={inputId}>{inputLabel}</label> }
            <input
                className={styles['hs-input']}
                type={inputType}
                id={inputId}
                value={props.value}
                readOnly={props.readOnly}
                maxLength={props.maxLength}
                minLength={props.minLength}
                placeholder={props.placeholder}
                min={props.min}
                max={props.max}
                title={props.titleName}
                onChange={handleChange}
            />
        </div>
    </>
  );
}

export default Input;