import React, { useState, useEffect } from "react";
import { Outlet } from 'react-router-dom';
import HsHeader from "./HsHeader";
import HsFooter from "./HsFooter";

function HsLayout() {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
      function handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
        if (scrollTop > 200) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
  
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    function handleClick() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const evt = new CustomEvent('onTopButtonClicked');
      document.dispatchEvent(evt);
    }
  
    return (
        <>
            <h1 className="blind">휴머스온</h1>
            <HsHeader />
            <main className="hs-main">
              <Outlet />
              <button type="button" className={`hs-top-button ${showButton ? "show" : ""}`} onClick={handleClick}>
                  <span className="blind">위로 가기</span>
              </button>
            </main>
            <HsFooter />
        </>
    );
}

export default HsLayout;