const HsHistoryItems = [
    {
        year: "2022",
        item: [
            {
                month: "11",
                contents: "소셜 DNA 혁신상 수상"
            },
            {
                month: "10",
                contents: "휴머스온 법인 물적 분할"
            },
            {
                month: "3",
                contents: "AI 바우처 공급기업 선정"
            },
            {
                month: "2",
                contents: "데이터 바우처 판매 및 공급기업 선정"
            }
        ]
    },
    {
        year: "2021",
        item: [
            {
                month: "1",
                contents: "인공지능 마케팅 플랫폼 'AI Help U' 서비스 오픈"
            }
        ]
    },
    {
        year: "2020",
        item: [
            {
                month: "12",
                contents: "데이터 기반의 AI 광고 'AID' 서비스 오픈"
            },
            {
                month: "2",
                contents: "한국 소프트웨어산업협회 [IT 솔루션 부문 최우수상] 수상"
            },
            {
                month: "1",
                contents: "고용노동부 주관 [청년친화 강소 기업] 수상"
            }
        ]
    },
    {
        year: "2019",
        item: [
            {
                month: "10",
                contents: "대한상공회의소 주관, [일하기 좋은 중소기업] 선정"
            },
            {
                month: "1",
                contents: "2년 연속 [고용노동부 청년친화 강소기업] 선정"
            }
        ]
    },
    {
        year: "2018",
        item: [
            {
                month: "11",
                contents: "빅데이터 기반 'TasOn 마케팅자동화' 서비스 출시"
            },
            {
                month: "1",
                contents: "고용노동부 청년친화 강소기업 선정"
            }
        ]
    },
    {
        year: "2017",
        item: [
            {
                month: "10",
                contents: "[일하기 좋은 SW기업 - 일과 업무의 균형] 부분 최우수상"
            },
            {
                month: "7",
                contents: "빅데이터 마케팅 플랫폼 TasOn 서비스 오픈"
            }
        ]
    },
    {
        year: "2016",
        item: [
            {
                month: "7",
                contents: "카카오 알림톡 공식딜러사 선정"
            },
            {
                month: "3",
                contents: "통합 메시징 플랫폼 TMS v3.0 출시"
            }
        ]
    },
    {
        year: "2015",
        item: [
            {
                month: "4",
                contents: "Push Marketing Platform 'Pushpia' 서비스 오픈"
            }
        ]
    },
    {
        year: "2014",
        item: [
            {
                month: "3",
                contents: "humuson International 설립 (홍콩, 북경, 상하이)"
            }
        ]
    },
    {
        year: "2013",
        item: [
            {
                month: "7",
                contents: "푸시 마케팅 솔루션 'PMS' 출시"
            },
            {
                month: "4",
                contents: "휴머스온(humusOn)으로 사명 변경"
            }
        ]
    },
    {
        year: "2012",
        item: [
            {
                month: "7",
                contents: "eHumus Inc. 법인설립(미국 얼바인), mailPlants 오픈"
            }
        ]
    },
    {
        year: "2010",
        item: [
            {
                month: "2",
                contents: "모바일 메신저 '햇살' 출시"
            }
        ]
    },
    {
        year: "2008",
        item: [
            {
                month: "6",
                contents: "eMs Web Tract 특허 등록"
            },
            {
                month: "2",
                contents: "eMs Eraser 특허 등록"
            }
        ]
    },
    {
        year: "2004",
        item: [
            {
                month: "4",
                contents: "기술신용보증 우량 기술기업 선정"
            },
            {
                month: "3",
                contents: "정보통신부 벤처기업 지정(연구개발 기업형)"
            }
        ]
    },
    {
        year: "2003",
        item: [
            {
                month: "9",
                contents: "중소기업청 e-Business 분야 INNO-BIZ 기업 선정"
            }
        ]
    },
    {
        year: "2000",
        item: [
            {
                month: "8",
                contents: "eMs(E-mail Marketing Server) 발표"
            }
        ]
    },
    {
        year: "1998",
        item: [
            {
                month: "7",
                contents: "(주) 에이메일 창립"
            }
        ]
    },
    // {
    //     year: "1998",
    //     item: [
    //         {
    //             month: "8",
    //             contents: "eMs(E-mail Marketing Server) 발표"
    //         }
    //     ]
    // }
]

export default HsHistoryItems;